import { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Visacard = ({
  title = 'visa',
  titleId = 'Visacard-icon',
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width={30}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2.58862C0 1.48405 0.895431 0.588623 2 0.588623H30C31.1046 0.588623 32 1.48405 32 2.58862V19.5886C32 20.6932 31.1046 21.5886 30 21.5886H2C0.895431 21.5886 0 20.6932 0 19.5886V2.58862Z"
      fill="#F2F2F2"
    />
    <path
      d="M13.3221 13.6003L14.2601 8.09655H15.7604L14.8218 13.6003H13.3221Z"
      fill="#1B54A2"
    />
    <path
      d="M20.2625 8.23212C19.9653 8.12062 19.4996 8.001 18.918 8.001C17.4355 8.001 16.3914 8.74745 16.3826 9.81728C16.3743 10.6082 17.1279 11.0494 17.697 11.3127C18.281 11.5823 18.4773 11.7543 18.4745 11.9954C18.4708 12.3643 18.0081 12.5328 17.5769 12.5328C16.9765 12.5328 16.6575 12.4493 16.1649 12.2439L15.9716 12.1563L15.7611 13.3884C16.1114 13.5421 16.7593 13.6752 17.4323 13.6821C19.0091 13.6821 20.0328 12.9443 20.0444 11.8016C20.0499 11.1757 19.6503 10.6991 18.7849 10.3064C18.2606 10.0519 17.9395 9.88196 17.9427 9.62395C17.9427 9.39538 18.2147 9.15058 18.8018 9.15058C19.2924 9.14316 19.6477 9.25003 19.9245 9.36153L20.0592 9.42505L20.2625 8.23212Z"
      fill="#1B54A2"
    />
    <path
      d="M22.2593 11.6487C22.3836 11.3316 22.8572 10.1102 22.8572 10.1102C22.8484 10.1248 22.9803 9.79166 23.0561 9.58488L23.1574 10.0594C23.1574 10.0594 23.4446 11.3733 23.5046 11.6487H22.2593ZM24.1092 8.10125H22.9499C22.5911 8.10125 22.3221 8.19931 22.1645 8.5577L19.9365 13.6016H21.5117C21.5117 13.6016 21.7695 12.9235 21.8277 12.7744C21.9997 12.7744 23.5301 12.777 23.749 12.777C23.7937 12.9696 23.9314 13.6016 23.9314 13.6016H25.3235L24.1092 8.10125Z"
      fill="#1B54A2"
    />
    <path
      d="M12.0635 8.10072L10.5947 11.8538L10.4382 11.0912C10.1649 10.2119 9.31301 9.25934 8.36047 8.78226L9.70362 13.5957L11.2909 13.5938L13.6526 8.10072H12.0635Z"
      fill="#1B54A2"
    />
    <path
      d="M9.23231 8.09837H6.81331L6.79407 8.21289C8.67619 8.66841 9.92151 9.76931 10.4385 11.0923L9.91246 8.56293C9.82159 8.21451 9.55825 8.11043 9.23231 8.09837Z"
      fill="#F89C0E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 6.58862C0 3.27491 2.68629 0.588623 6 0.588623H26C29.3137 0.588623 32 3.27491 32 6.58862V15.5886C32 18.9023 29.3137 21.5886 26 21.5886H6C2.68629 21.5886 0 18.9023 0 15.5886V6.58862Z"
      fill="#E5E5E5"
      fillOpacity="0.01"
    />
  </svg>
);

export default Visacard;
