import { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
  color?: string;
}

const ShareIcon = ({
  title = 'Share',
  titleId = 'Share-icon',
  color = '#fff',
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width={15}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    color={color}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.78174 1.3C9.22636 1.3 8.76262 1.75796 8.76262 2.33966C8.76262 2.52007 8.80722 2.68857 8.88533 2.83521C8.89051 2.84316 8.89553 2.85126 8.9004 2.8595C8.90758 2.87165 8.91431 2.88393 8.92061 2.89633C9.10262 3.18832 9.42307 3.37932 9.78174 3.37932C10.3371 3.37932 10.8009 2.92136 10.8009 2.33966C10.8009 1.75796 10.3371 1.3 9.78174 1.3ZM8.18537 4.03686C8.60006 4.43439 9.16102 4.67932 9.78174 4.67932C11.07 4.67932 12.1009 3.6243 12.1009 2.33966C12.1009 1.05502 11.07 0 9.78174 0C8.49346 0 7.46262 1.05502 7.46262 2.33966C7.46262 2.53702 7.48695 2.72895 7.5328 2.91248L4.70164 4.585C4.28696 4.18747 3.72599 3.94254 3.10526 3.94254C1.81697 3.94254 0.786133 4.99756 0.786133 6.2822C0.786133 7.56684 1.81697 8.62185 3.10526 8.62185C3.72613 8.62185 4.2872 8.37682 4.70191 7.97913L7.53343 9.64941C7.48717 9.8337 7.46262 10.0265 7.46262 10.2247C7.46262 11.5094 8.49346 12.5644 9.78174 12.5644C11.07 12.5644 12.1009 11.5094 12.1009 10.2247C12.1009 8.94009 11.07 7.88508 9.78174 7.88508C9.16194 7.88508 8.60173 8.12927 8.18723 8.52575L5.3543 6.85464C5.40009 6.67123 5.42439 6.47942 5.42439 6.2822C5.42439 6.08485 5.40006 5.89291 5.35421 5.70939L8.18537 4.03686ZM3.96658 5.72582C3.97283 5.73812 3.97951 5.7503 3.98663 5.76235C3.99145 5.7705 3.99641 5.7785 4.00152 5.78636C4.07972 5.93306 4.12439 6.10167 4.12439 6.2822C4.12439 6.46272 4.07973 6.63133 4.00152 6.77803C3.99633 6.78601 3.9913 6.79413 3.98642 6.80241C3.97938 6.81434 3.97277 6.8264 3.96658 6.83858C3.7846 7.13072 3.46405 7.32185 3.10526 7.32185C2.54988 7.32185 2.08613 6.8639 2.08613 6.2822C2.08613 5.7005 2.54988 5.24254 3.10526 5.24254C3.46405 5.24254 3.7846 5.43367 3.96658 5.72582ZM8.76262 10.2247C8.76262 10.0568 8.80126 9.89919 8.86961 9.75992C8.88259 9.74227 8.8948 9.72379 8.90617 9.70452C8.91934 9.6822 8.93101 9.65941 8.94124 9.63628C9.12623 9.3623 9.43626 9.18508 9.78174 9.18508C10.3371 9.18508 10.8009 9.64303 10.8009 10.2247C10.8009 10.8064 10.3371 11.2644 9.78174 11.2644C9.22636 11.2644 8.76262 10.8064 8.76262 10.2247Z"
      fill="currentColor"
    />
  </svg>
);

export default ShareIcon;
