import TextArea from 'components/TextArea';

import * as S from './styles';

function OrderNotes() {
  return (
    <S.Container>
      <TextArea placeholder="Por favor, sem suco de limão." />
    </S.Container>
  );
}

export default OrderNotes;
