import { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Elocard = ({
  title = 'elo',
  titleId = 'elocard-icon',
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width={30}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2.58862C0 1.48405 0.895431 0.588623 2 0.588623H30C31.1046 0.588623 32 1.48405 32 2.58862V19.5886C32 20.6932 31.1046 21.5886 30 21.5886H2C0.895431 21.5886 0 20.6932 0 19.5886V2.58862Z"
      fill="#080A0F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5352 10.1734L8.68699 11.8318C8.68153 11.7655 8.67841 11.6984 8.67841 11.6306C8.67841 10.4136 9.66444 9.42603 10.8822 9.42603C11.5406 9.42603 12.1311 9.71544 12.5352 10.1734ZM10.8822 7.77848C12.6974 7.77848 14.2194 9.03442 14.6274 10.7257L13.0742 11.4043L13.0734 11.4012L11.4852 12.0994L7.67521 13.7649C7.26722 13.1541 7.0293 12.42 7.0293 11.6306C7.0293 9.50326 8.75408 7.77848 10.8822 7.77848Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5735 14.387C12.1943 15.696 10.3697 15.8247 8.7666 14.8512L9.67229 13.4743C10.5842 14.0188 11.5016 13.9307 12.4213 13.2075L13.5735 14.387Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.142 13.4328L15.1318 6.14828H16.5126V13.2362C16.5126 13.304 16.5212 13.3641 16.6101 13.3992L17.8107 13.8657L17.2701 15.2722L15.8636 14.677C15.33 14.4516 15.1428 14.1247 15.142 13.4328Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.3393 13.3103C19.8221 12.9078 19.4882 12.2806 19.4882 11.5738C19.4882 10.9467 19.7519 10.3811 20.1739 9.98012L19.2831 8.5354C18.3867 9.24528 17.811 10.3421 17.811 11.5738C17.811 12.9023 18.4788 14.074 19.4976 14.7722L20.3393 13.3103Z"
      fill="#43A0DB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.943 9.5049C21.1754 9.42143 21.4251 9.37541 21.6864 9.37541C22.628 9.37541 23.4307 9.9675 23.7443 10.7991L25.4519 10.6563C25.04 8.95884 23.511 7.69821 21.6864 7.69821C21.1715 7.69821 20.6793 7.79963 20.23 7.98217L20.943 9.5049Z"
      fill="#EFEF25"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.8826 11.682C23.8273 12.8467 22.8646 13.7742 21.6867 13.7742C21.5018 13.7742 21.3224 13.7508 21.1508 13.7079L20.4814 15.2587C20.8606 15.3835 21.2662 15.4514 21.6867 15.4514C23.7734 15.4514 25.4748 13.8015 25.5583 11.7343L23.8826 11.682Z"
      fill="#EC3321"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 6.58862C0 3.27491 2.68629 0.588623 6 0.588623H26C29.3137 0.588623 32 3.27491 32 6.58862V15.5886C32 18.9023 29.3137 21.5886 26 21.5886H6C2.68629 21.5886 0 18.9023 0 15.5886V6.58862Z"
      fill="#E5E5E5"
      fillOpacity="0.01"
    />
  </svg>
);

export default Elocard;
