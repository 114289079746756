import { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}

const VRCard = ({
  title = 'VRCard',
  titleId = 'VRCard-icon',
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width={30}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2C0 0.895431 0.895431 0 2 0H30C31.1046 0 32 0.895431 32 2V19C32 20.1046 31.1046 21 30 21H2C0.895431 21 0 20.1046 0 19V2Z"
      fill="#F2F2F2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.88232 4.32349V16.6961H22.2353V4.32349H9.88232Z"
      fill="#009035"
      stroke="#009035"
      strokeWidth="0.216"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6506 10.5204H13.1627L14.036 13.4191L15.6366 8.97778H19.1618C20.8445 8.97789 22.1026 11.4687 19.9417 12.6308L21.0988 15.5506H18.3822L17.232 11.8757H18.3822C18.9244 11.7151 18.904 10.818 18.3822 10.6775H17.232L15.2928 15.5507L12.5771 15.5506L10.6506 10.5204Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6506 10.4996H13.1627H13.1782L13.1827 10.5145L14.0378 13.3528L15.617 8.97091L15.622 8.95703H15.6366H19.1617V8.95708C19.6862 8.95713 20.17 9.19632 20.5239 9.5615C20.7643 9.80962 20.9452 10.1161 21.0384 10.4453C21.1317 10.7748 21.1373 11.1275 21.0274 11.4673C20.8857 11.9052 20.5523 12.3218 19.9679 12.6404L21.1181 15.5431L21.1295 15.5716H21.0987H18.3821H18.3668L18.3622 15.5569L17.212 11.8821L17.2035 11.855H17.2319H18.3792C18.5161 11.8135 18.6155 11.7228 18.6783 11.6095H18.6782C18.7346 11.5076 18.7617 11.3872 18.7602 11.2676C18.7588 11.1479 18.7287 11.0289 18.671 10.9296C18.6073 10.82 18.5096 10.7344 18.3792 10.6985H17.246L15.3122 15.5585L15.3069 15.5717L15.2928 15.5716L12.5771 15.5716H12.5627L12.5575 15.5582L10.631 10.5279L10.6202 10.4996L10.6506 10.4996ZM13.1471 10.5414H10.681L12.5914 15.5299L15.2787 15.53L17.2125 10.67L17.2178 10.6567H17.2319H18.3821H18.3848L18.3875 10.6574C18.5307 10.696 18.6377 10.7893 18.7071 10.9086C18.7685 11.0142 18.8004 11.1405 18.8019 11.2671C18.8035 11.3937 18.7747 11.5213 18.7147 11.6296H18.7147C18.6464 11.753 18.5378 11.8515 18.388 11.8959L18.3851 11.8968H18.3821H17.2603L18.3974 15.5298H21.0681L19.9223 12.6386L19.9155 12.6213L19.9318 12.6125C20.5159 12.2984 20.8478 11.8868 20.9877 11.4545C21.095 11.1229 21.0894 10.7786 20.9982 10.4566C20.907 10.1341 20.7297 9.83376 20.4941 9.59058C20.1475 9.23302 19.6743 8.99877 19.1617 8.99877V8.99883H15.6512L14.0555 13.4263L14.0341 13.4857L14.0159 13.4252L13.1471 10.5414Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 6C0 2.68629 2.68629 0 6 0H26C29.3137 0 32 2.68629 32 6V15C32 18.3137 29.3137 21 26 21H6C2.68629 21 0 18.3137 0 15V6Z"
      fill="#E5E5E5"
      fillOpacity="0.01"
    />
  </svg>
);

export default VRCard;
