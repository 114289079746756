import { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Alelo = ({
  title = 'Alelo',
  titleId = 'Alelo-icon',
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width={30}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2C0 0.895431 0.895431 0 2 0H30C31.1046 0 32 0.895431 32 2V19C32 20.1046 31.1046 21 30 21H2C0.895431 21 0 20.1046 0 19V2Z"
      fill="#007B5D"
    />
    <path
      d="M17.2256 11.6282C16.977 11.872 16.6362 12.0207 16.2608 12.0145C16.0032 12.0112 15.7646 11.934 15.5616 11.8057L15.0574 12.6058C15.4025 12.8231 15.8096 12.9528 16.2466 12.9594C16.8838 12.9694 17.4642 12.7185 17.8869 12.3052L17.2256 11.6282Z"
      fill="white"
    />
    <path
      d="M14.9371 10.8052C14.9315 10.7512 14.9277 10.6968 14.9286 10.6409C14.9405 9.89386 15.5554 9.29736 16.3025 9.3092C16.7091 9.31535 17.0703 9.5014 17.3141 9.78923L14.9371 10.8052ZM16.3167 8.36474C15.0479 8.34581 14.0036 9.35796 13.9842 10.6272C13.9775 11.102 14.1148 11.5456 14.3558 11.9163L18.5185 10.1377C18.2842 9.13498 17.3932 8.38131 16.3167 8.36474Z"
      fill="white"
    />
    <path
      d="M19.8233 7.32945V11.7662L20.5925 12.0848L20.2294 12.9592L19.4682 12.643C19.2959 12.5696 19.1808 12.456 19.0933 12.3277C19.008 12.198 18.9456 12.0186 18.9456 11.7776V7.32945H19.8233Z"
      fill="white"
    />
    <path
      d="M13.1248 7.32945V11.7662L13.8941 12.0848L13.531 12.9592L12.7697 12.643C12.5979 12.5696 12.4824 12.456 12.3948 12.3277C12.3096 12.198 12.2471 12.0186 12.2471 11.7776V7.32945H13.1248Z"
      fill="white"
    />
    <path
      d="M23.611 9.44237C24.2851 9.76619 24.5682 10.5743 24.2449 11.2484L25.0961 11.657C25.6462 10.5137 25.1642 9.14081 24.02 8.59118C23.1513 8.17316 22.15 8.35164 21.4783 8.96328L22.1145 9.66251C22.5103 9.30177 23.0997 9.19573 23.611 9.44237Z"
      fill="#B3DD3A"
    />
    <path
      d="M22.4391 11.8814C21.766 11.5575 21.4824 10.749 21.8052 10.0762V10.0758L20.9541 9.66627C20.4049 10.8105 20.8859 12.1829 22.0296 12.733C22.8998 13.1506 23.9001 12.9721 24.5723 12.3604L23.9361 11.6617C23.5398 12.0215 22.9509 12.1275 22.4391 11.8814Z"
      fill="white"
    />
    <path
      d="M11.3805 11.9453V10.6671V10.6614C11.3805 9.63648 10.7092 8.76967 9.78181 8.47568C9.56263 8.40609 9.32971 8.36775 9.08732 8.36727C8.84967 8.36775 8.62101 8.4042 8.40561 8.47095C8.0463 8.5822 7.72485 8.78009 7.46542 9.03951C7.05072 9.45422 6.79413 10.028 6.79413 10.6614C6.79413 11.9278 7.82048 12.9541 9.08732 12.9546H9.08827C9.45516 12.9546 9.80264 12.8675 10.1108 12.7136L9.73968 11.8483C9.54606 11.9548 9.32545 12.0158 9.08732 12.0158C8.33934 12.0149 7.73385 11.4089 7.73195 10.6614H7.73243C7.7329 10.4313 7.79065 10.215 7.89149 10.0256C8.07375 9.68524 8.39614 9.43197 8.78197 9.3425C8.88044 9.3193 8.98223 9.30604 9.08732 9.30604C9.28757 9.30652 9.47694 9.35149 9.64784 9.42913C10.1156 9.64311 10.4417 10.1132 10.4427 10.6614H10.4432V11.9349C10.4436 12.1749 10.5061 12.3548 10.5909 12.4845C10.6785 12.6123 10.7935 12.7264 10.9658 12.8003L11.3957 12.9707L11.7592 12.0963L11.3805 11.9453Z"
      fill="white"
    />
  </svg>
);

export default Alelo;
