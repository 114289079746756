import { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Cabalcard = ({
  title = 'Cabal',
  titleId = 'Cabalcard-icon',
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width={30}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2.58862C0 1.48405 0.895431 0.588623 2 0.588623H30C31.1046 0.588623 32 1.48405 32 2.58862V19.5886C32 20.6932 31.1046 21.5886 30 21.5886H2C0.895431 21.5886 0 20.6932 0 19.5886V2.58862Z"
      fill="#006398"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6364 11.216C19.2964 11.216 19.6297 11.1159 19.6297 11.5523C19.6297 12.0993 19.5495 12.0157 18.6367 12.0157L18.6364 11.216ZM18.6433 9.99072C19.4031 9.99072 19.5201 9.90158 19.5199 10.3832C19.5197 10.854 19.2864 10.7631 18.6362 10.7631L18.6433 9.99072ZM18.0293 12.4773L19.3481 12.4844C19.8262 12.4844 20.2308 12.298 20.2308 11.8171C20.2308 11.4475 20.29 11.0693 19.8021 10.9444C20.1099 10.6849 20.1257 10.8249 20.1257 10.1841C20.1257 9.72155 19.8566 9.55506 19.4125 9.52646C19.0671 9.50422 18.3664 9.49571 18.0293 9.52729V12.4773Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.48336 9.78598C9.52458 9.88145 10.4866 10.7879 10.5409 10.8697C10.6693 11.0634 10.4012 11.2469 10.3197 11.3285C10.0769 11.5718 9.83748 11.794 9.59069 12.0339C9.49901 12.123 9.48138 12.1035 9.48219 12.2783C9.48328 12.5138 9.4683 13.2159 9.49277 13.3516L11.5328 11.3496C11.6209 11.268 11.7629 11.1766 11.7717 10.9975C11.7826 10.777 11.4718 10.5834 11.258 10.3506C10.9057 9.96697 10.4524 9.56906 10.0766 9.19285C9.9761 9.09228 9.89123 8.99681 9.79532 8.90032C9.73143 8.83604 9.54685 8.65367 9.48466 8.61621L9.48336 9.78598Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.07779 13.3516C9.10059 13.1682 9.08732 12.4299 9.08713 12.1914C9.08705 12.091 8.35754 11.4359 8.23974 11.3175C8.1676 11.245 7.99224 11.1171 7.99657 10.978C8.00053 10.8505 8.38231 10.527 8.53409 10.3584C8.62735 10.2549 8.72099 10.1691 8.81978 10.0703C8.97049 9.91961 9.08713 9.8549 9.08713 9.74185L9.08613 8.61621C8.98485 8.67698 7.86716 9.82591 7.61976 10.0405C7.39756 10.2331 7.26764 10.4157 7.03667 10.6259C6.59866 11.0245 6.79401 11.1335 7.31904 11.6201C7.71588 11.9879 8.10155 12.4289 8.49575 12.7827C8.60571 12.8814 8.68194 12.9652 8.78713 13.0653C8.87553 13.1494 9.01151 13.2616 9.07779 13.3516Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3635 10.0149L16.7912 11.3821L15.9329 11.371L16.3635 10.0149ZM14.9402 12.4764L15.5749 12.4856L15.7741 11.875L16.921 11.8731C16.9966 11.9367 17.1127 12.3637 17.1416 12.4818L17.7778 12.4846L16.7809 9.52633C15.7747 9.47494 15.9487 9.46501 15.5439 10.6844C15.4061 11.0997 15.0248 12.1523 14.9402 12.4764Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.7879 10.0172L22.2165 11.3828L21.3538 11.38L21.7879 10.0172ZM20.3647 12.4849L20.9593 12.4863C21.0217 12.4713 21.0622 12.2823 21.0917 12.1938C21.1257 12.0918 21.1687 11.9777 21.1927 11.8744H22.3786L22.5703 12.485L23.2009 12.4841L22.4525 10.2594C22.4078 10.1286 22.3673 10.0083 22.324 9.88034C22.2909 9.78255 22.2606 9.57448 22.1731 9.52382C21.7492 9.52382 21.4139 9.46518 21.2662 9.80875L20.3647 12.4849Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7441 10.272V11.6181C12.7441 12.2506 13.2132 12.4788 13.8307 12.4788L14.6811 12.471C14.6807 11.9336 14.5586 11.9655 14.0514 11.966C13.1977 11.9668 13.3505 11.8166 13.3505 10.3824C13.3505 10.0633 13.5124 9.94626 13.8307 9.94626H14.7024C14.7391 9.34169 14.5539 9.43344 13.7866 9.43344C13.2072 9.43344 12.7441 9.67962 12.7441 10.272Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.4086 12.4862C23.7128 12.4862 25.1393 12.5232 25.3178 12.4717C25.3178 12.3808 25.331 12.1108 25.3189 12.041C25.3021 11.944 25.1818 11.984 24.9313 11.984C24.6262 11.9841 24.321 11.984 24.0159 11.984V9.49039C23.2308 9.32383 23.4086 10.0267 23.4086 10.6714C23.4086 11.2763 23.4086 11.8813 23.4086 12.4862Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 6.58862C0 3.27491 2.68629 0.588623 6 0.588623H26C29.3137 0.588623 32 3.27491 32 6.58862V15.5886C32 18.9023 29.3137 21.5886 26 21.5886H6C2.68629 21.5886 0 18.9023 0 15.5886V6.58862Z"
      fill="#E5E5E5"
      fillOpacity="0.01"
    />
  </svg>
);

export default Cabalcard;
