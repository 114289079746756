import { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}

const AmericanExpress = ({
  title = 'AmericanExpress',
  titleId = 'AmericanExpress-icon',
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width={30}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2.58862C0 1.48405 0.895431 0.588623 2 0.588623H30C31.1046 0.588623 32 1.48405 32 2.58862V19.5886C32 20.6932 31.1046 21.5886 30 21.5886H2C0.895431 21.5886 0 20.6932 0 19.5886V2.58862Z"
      fill="#3B6DBE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3912 14.7224V11.8133H13.4717L13.8023 12.2439L14.1433 11.8133H25.3236V14.5218C25.3236 14.5218 25.0312 14.7193 24.6933 14.7224H18.5026L18.1296 14.264V14.7224H16.9089V13.9397C16.9089 13.9397 16.7423 14.0487 16.3813 14.0487H15.9659V14.7224H14.1171L13.7871 14.2823L13.4523 14.7224H10.3912Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.79407 9.62084L7.48874 8.00151H8.69001L9.08397 8.90888V8.00151H10.5776L10.8117 8.65689L11.0396 8.00151H17.7422V8.33103C17.7422 8.33103 18.0948 8.00151 18.6742 8.00151L20.8489 8.00884L21.2366 8.90416V8.00151H22.486L22.8297 8.51596V8.00151H24.0912V10.9106H22.8297L22.5002 10.3946V10.9106H20.6645L20.4801 10.4517H19.986L19.8042 10.9106H18.5595C18.0613 10.9106 17.7422 10.5879 17.7422 10.5879V10.9106H15.8652L15.4927 10.4517V10.9106H8.51189L8.32748 10.4517H7.83555L7.65219 10.9106H6.79407V9.62084Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.08137 8.86713L8.38785 9.62939H7.77438L8.08137 8.86713ZM7.73456 8.36001L6.79785 10.5383H7.40766L7.58054 10.1025H8.58588L8.75771 10.5383H9.38113L8.44495 8.36001H7.73456Z"
      fill="#3B6DBE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.44556 10.5383V8.36001L10.3121 8.36368L10.8166 9.76769L11.3085 8.36001H12.1682V10.5383H11.6239V8.93367L11.0466 10.5383H10.5693L9.9904 8.93367V10.5383H9.44556Z"
      fill="#3B6DBE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.541 10.5383V8.36001H14.318V8.84723H13.0911V9.21971H14.2892V9.67863H13.0911V10.0658H14.318V10.5383H12.541Z"
      fill="#3B6DBE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1779 8.84723H15.8003C15.9496 8.84723 16.058 8.96405 16.058 9.07669C16.058 9.22128 15.9176 9.30563 15.8087 9.30563H15.1779V8.84723ZM14.6331 8.36001V10.5383H15.1779V9.76455H15.4068L16.0601 10.5383H16.7255L16.0088 9.73574C16.3027 9.71111 16.6065 9.4586 16.6065 9.06673C16.6065 8.60833 16.2466 8.36001 15.8448 8.36001H14.6331Z"
      fill="#3B6DBE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.3846 10.5383H16.8282V8.36001H17.3846V10.5383Z"
      fill="#3B6DBE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.7029 10.5383H18.583C18.0025 10.5383 17.6494 10.081 17.6494 9.45808C17.6494 8.81998 17.9983 8.36001 18.7318 8.36001H19.3337V8.87604H18.7098C18.4117 8.87604 18.2011 9.10865 18.2011 9.46384C18.2011 9.88557 18.4421 10.0626 18.7889 10.0626H18.9319L18.7029 10.5383Z"
      fill="#3B6DBE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.2346 8.86713L20.541 9.62939H19.9276L20.2346 8.86713ZM19.8878 8.36001L18.951 10.5383H19.5609L19.7337 10.1025H20.7391L20.9109 10.5383H21.5343L20.5981 8.36001H19.8878Z"
      fill="#3B6DBE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5979 10.5383V8.36001H22.2905L23.1743 9.72893V8.36001H23.7191V10.5383H23.0491L22.1427 9.13379V10.5383H21.5979Z"
      fill="#3B6DBE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.764 14.3508V12.1725H12.5411V12.6597H11.3141V13.0322H12.5122V13.4911H11.3141V13.8778H12.5411V14.3508H10.764Z"
      fill="#3B6DBE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.4713 14.3508V12.1725H21.2483V12.6597H20.0214V13.0322H21.2138V13.4911H20.0214V13.8778H21.2483V14.3508H19.4713Z"
      fill="#3B6DBE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6095 14.3508L13.475 13.2753L12.5891 12.1725H13.2754L13.8024 12.8541L14.3321 12.1725H14.9911L14.1168 13.2617L14.9838 14.3508H14.298L13.7857 13.6803L13.2859 14.3508H12.6095Z"
      fill="#3B6DBE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6077 12.665H16.2112C16.3684 12.665 16.4805 12.7608 16.4805 12.9159C16.4805 13.061 16.3689 13.1668 16.2096 13.1668H15.6077V12.665ZM15.0487 12.1725V14.3508H15.6077V13.663H16.1808C16.6659 13.663 17.0337 13.4057 17.0337 12.9049C17.0337 12.4905 16.745 12.1725 16.2515 12.1725H15.0487Z"
      fill="#3B6DBE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.8146 12.6597H18.437C18.5863 12.6597 18.6947 12.7766 18.6947 12.8887C18.6947 13.0338 18.5543 13.1181 18.4454 13.1181H17.8146V12.6597ZM17.2698 12.1725V14.3508H17.8146V13.5765H18.0436L18.6968 14.3508H19.3622L18.6455 13.5482C18.9399 13.5231 19.2433 13.2711 19.2433 12.8787C19.2433 12.4208 18.8833 12.1725 18.4815 12.1725H17.2698Z"
      fill="#3B6DBE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.4999 14.3508V13.8778H22.5901C22.751 13.8778 22.8207 13.7908 22.8207 13.6954C22.8207 13.6038 22.7515 13.511 22.5901 13.511H22.0977C21.6692 13.511 21.4308 13.2501 21.4308 12.8588C21.4308 12.5094 21.6492 12.1725 22.2852 12.1725H23.3456L23.1167 12.6629H22.1993C22.0238 12.6629 21.9699 12.7551 21.9699 12.8426C21.9699 12.9327 22.0369 13.0322 22.1705 13.0322H22.6865C23.1638 13.0322 23.3707 13.3031 23.3707 13.6577C23.3707 14.0386 23.1402 14.3508 22.6609 14.3508H21.4999Z"
      fill="#3B6DBE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.4991 14.3508V13.8778H24.5427C24.704 13.8778 24.7737 13.7908 24.7737 13.6954C24.7737 13.6038 24.704 13.511 24.5427 13.511H24.0963C23.6683 13.511 23.4299 13.2501 23.4299 12.8588C23.4299 12.5094 23.6484 12.1725 24.2844 12.1725H25.3238L25.0943 12.6629H24.1985C24.023 12.6629 23.969 12.7551 23.969 12.8426C23.969 12.9327 24.0355 13.0322 24.1697 13.0322H24.6396C25.1168 13.0322 25.3238 13.3031 25.3238 13.6577C25.3238 14.0386 25.0927 14.3508 24.6134 14.3508H23.4991Z"
      fill="#3B6DBE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 6.58862C0 3.27491 2.68629 0.588623 6 0.588623H26C29.3137 0.588623 32 3.27491 32 6.58862V15.5886C32 18.9023 29.3137 21.5886 26 21.5886H6C2.68629 21.5886 0 18.9023 0 15.5886V6.58862Z"
      fill="#E5E5E5"
      fillOpacity="0.01"
    />
  </svg>
);

export default AmericanExpress;
