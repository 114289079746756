import { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}

const TicketCard = ({
  title = 'TicketCard',
  titleId = 'TicketCard-icon',
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width={30}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2C0 0.895431 0.895431 0 2 0H30C31.1046 0 32 0.895431 32 2V19C32 20.1046 31.1046 21 30 21H2C0.895431 21 0 20.1046 0 19V2Z"
      fill="#F2F2F2"
    />
    <path
      d="M20.7247 10.7637C20.7247 10.0519 20.2565 9.4749 19.3941 9.4749C18.6591 9.4749 18.0634 9.94944 18.0634 10.7637C18.0634 11.5141 18.6136 12.0525 19.536 12.0525C19.8879 12.0525 20.1866 11.93 20.1866 11.93L20.236 11.3659C20.236 11.3659 20.0391 11.5059 19.6389 11.5059C18.9513 11.5059 18.8648 10.9418 18.8648 10.9418H20.7247V10.7637ZM18.1319 12.0021C18.1319 12.0021 18.0245 11.7871 17.8951 11.3988C17.8004 11.1147 17.7342 10.785 17.6192 10.67C17.471 10.5218 17.2322 10.5465 17.2322 10.5465L18.0392 9.52533H17.2383L16.5527 10.5048H16.4725V8.65651H15.7519V12.0021H16.4725V10.9789C16.4725 10.9789 16.5075 10.9789 16.7751 10.9789C17.1148 10.9789 17.086 11.263 17.1961 11.5775C17.2868 11.8363 17.3578 12.0021 17.3578 12.0021H18.1319ZM14.3339 10.7647C14.3339 10.3149 14.5948 10.0771 15.0278 10.0771C15.2149 10.0771 15.4101 10.1924 15.4101 10.1924L15.6159 9.64151C15.4241 9.53545 15.182 9.4749 14.8853 9.4749C14.1609 9.4749 13.5737 9.92063 13.5737 10.7637C13.5737 11.5883 14.1609 12.0525 14.8853 12.0525C15.0811 12.0525 15.2668 12.0104 15.4337 11.9349L15.4966 11.3371C15.4966 11.3371 15.3289 11.4524 15.0278 11.4524C14.6445 11.4524 14.3339 11.2047 14.3339 10.7647ZM13.3431 8.65651H12.6019V9.20828H13.3431V8.65651ZM13.3431 9.52533H12.6019V12.0021H13.3431V9.52533ZM22.0528 11.9959C21.384 14.6843 18.9541 16.6765 16.0588 16.6765C12.6476 16.6765 9.88232 13.9112 9.88232 10.5C9.88232 10.1006 9.92024 9.7101 9.99266 9.3318H10.6138V12.0021H11.3994V9.3318H12.3528L12.4454 8.65651H11.3994H10.6138H10.1622C10.9463 6.1458 13.2897 4.32357 16.0588 4.32357C19.47 4.32357 22.2353 7.08887 22.2353 10.5C22.2353 10.9992 22.176 11.4845 22.0642 11.9494C21.7119 11.4956 21.6195 10.9887 21.6195 10.6741C21.6195 10.3489 21.6195 10.1265 21.6195 10.1265H22.0189L22.1194 9.57475H21.6195V8.68121L20.8907 8.99004C20.8907 8.99004 20.8907 9.78885 20.8907 10.6535C20.8907 11.5183 21.3107 11.9959 21.3107 11.9959H22.0528ZM19.9519 10.46H18.8401C18.8401 10.46 18.9346 9.99062 19.396 9.99062C19.9189 9.99062 19.9519 10.46 19.9519 10.46Z"
      fill="#E81D24"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 6C0 2.68629 2.68629 0 6 0H26C29.3137 0 32 2.68629 32 6V15C32 18.3137 29.3137 21 26 21H6C2.68629 21 0 18.3137 0 15V6Z"
      fill="#E5E5E5"
      fillOpacity="0.01"
    />
  </svg>
);

export default TicketCard;
